<template>
  <div class="wrap">
    <!-- 今日信息 -->
    <div class="today-area">
      <!-- 今日充值 -->
      <TinyArea class="item" ref="RefTinyArea1"></TinyArea>
      <!-- 今日充值用户 -->
      <TinyArea class="item" ref="RefTinyArea2"></TinyArea>
      <!-- 今日新增用户 -->
      <TinyArea class="item" ref="RefTinyArea3"></TinyArea>
      <!-- 今日新用户充值率 -->
      <RingProgress class="item" ref="RefRingProgress"></RingProgress>
    </div>
    <div class="content">
      <!-- 趋势图 -->
      <Column class="trend" ref="RefColumn"></Column>
      <!-- 右侧 -->
      <div class="right">
        <div class="right-item" @click="$pub.JUMP_ROUTER('/operate-wait-library')">
          <div class="right-item-title">待入库新剧</div>
          <div class="right-item-content number">{{ unStorageNum }}</div>
        </div>
        <div class="right-item" @click="$pub.COPY(wxName)">
          <div class="right-item-title">平台客服</div>
          <div class="right-item-content wechat">微信号：{{ wxName }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { message } from 'ant-design-vue'
import { onMounted, ref } from 'vue'
import TinyArea from './components/TinyArea.vue'
import RingProgress from './components/RingProgress.vue'
import Column from './components/Column.vue'
import { dataPay, dataPayUser, dataNewUser, dataPayRate, dataTrend } from '@/api/operate'

// 子组件实例
let RefTinyArea1 = ref(null)
let RefTinyArea2 = ref(null)
let RefTinyArea3 = ref(null)
let RefRingProgress = ref(null)
let RefColumn = ref(null)
// 客服微信
const wxName = 'wishsunshine-J'
// 待入库数量
let unStorageNum = ref(0)

// 生命周期
onMounted(() => {
  // 充值率数据需要在这三个接口之后请求（后端缓存Redis）
  Promise.all([getDataPay(), getDataPayUser(), getDataNewUser()]).then(() => {
    // 新用户充值率
    getDataPayRate()
  })
  // 24小时趋势图
  getDataTrend()
})

// 将 object 格式的数据，重新按照固定的 key 处理为 array 格式
function resetData (data) {
  let source = []
  for(let i in data) {
    source.push({
      date: i,
      value: data[i]
    })
  }
  return source
}

// 充值金额
function getDataPay () {
  return new Promise((resolve, reject) => {
    dataPay().then(res => {
      resolve()
      const { code, data, msg } = res
      if (code === 0) {
        RefTinyArea1.value && RefTinyArea1.value.showTinyArea({
          dataSource: resetData(data.date_between),
          title: '今日充值',
          num: data.today_amount,
          desc: '累计充值',
          totalNum: data.total_amount,
          background: '#975FE4',
          tooltipDesc: '金额'
        })
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 充值用户
function getDataPayUser () {
  return new Promise((resolve, reject) => {
    dataPayUser().then(res => {
      resolve()
      const { code, data, msg } = res
      if (code === 0) {
        RefTinyArea2.value && RefTinyArea2.value.showTinyArea({
          dataSource: resetData(data.date_between),
          title: '今日充值用户',
          num: data.today_user_count,
          desc: '累计充值用户数',
          totalNum: data.total_user_count,
          background: '#3AA0FF'
        })
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 新增用户
function getDataNewUser () {
  return new Promise((resolve, reject) => {
    dataNewUser().then(res => {
      resolve()
      const { code, data, msg } = res
      if (code === 0) {
        RefTinyArea3.value && RefTinyArea3.value.showTinyArea({
          dataSource: resetData(data.date_between),
          title: '今日访问用户',
          num: data.td_user_log_count,
          desc: '累计用户数',
          totalNum: data.total_user_count,
          background: '#36CBCB'
        })
      } else {
        message.error(res.message || msg)
      }
    })
  })
}

// 新用户充值率
function getDataPayRate () {
  dataPayRate().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      RefRingProgress.value && RefRingProgress.value.showRingProgress({
        title: '今日充值率',
        num: data.td_user_pay_rate,
        desc: '累计充值率',
        totalNum: `${data.total_user_rate}%`,
        background: '#FAD337'
      })
      // 待入库数量
      unStorageNum.value = data.un_storage
    } else {
      message.error(res.message || msg)
    }
  })
}

// 24小时趋势图
function getDataTrend () {
  dataTrend().then(res => {
    const { code, data, msg } = res
    if (code === 0) {
      // 数据源
      let dataSource = []
      // 将数据处理为一维数组
      for(let i in data) {
        // 小时数
        let hour = i.toString().substring(8, 10) + '时'
        // 付费用户
        dataSource.push({
          hour,
          type: '付费用户',
          value: data[i].order_user_count
        })
        // 新增用户
        dataSource.push({
          hour,
          type: '新增用户',
          value: data[i].new_user_count
        })
        // 访问用户
        dataSource.push({
          hour,
          type: '访问用户',
          value: data[i].user_log_count
        })
      }
      // 展示趋势图
      RefColumn.value && RefColumn.value.showColumn({
        dataSource
      })
    } else {
      message.error(res.message || msg)
    }
  })
}
</script>

<style lang="less" scoped>
.wrap {
  display: flex;
  flex-direction: column;
  height: 100%;
  .today-area {
    display: flex;
    justify-content: space-between;
    min-height: 240px;
    flex: 3;
    .item {
      width: 23.5%;
      background: #fff;
      padding: 16px 20px 0;
      &:not(:last-child) {
        margin-right: 2%;
      }
    }
  }
  .content {
    margin-top: 24px;
    display: flex;
    justify-content: space-between;
    min-height: 400px;
    flex: 5;
    .trend {
      width: 74.5%;
      margin-right: 2%;
      background: #fff;
      padding: 16px 20px;
    }
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      .right-item {
        flex: 1;
        background: #fff;
        padding: 16px 20px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        &:not(:last-child) {
          margin-bottom: 24px;
        }
        &:hover {
          transition: all 0.3s;
          transform: scale(1.05);
        }
        .right-item-title {
          font-size: 16px;
        }
        .right-item-content {
          flex: 1;
          display: flex;
          align-items: center;
          &.number {
            justify-content: center;
            font-size: 36px;
            color: #7f7f81;
          }
          &.wechat {
            margin-left: 24px;
            color: #7f7f81;
          }
        }
      }
    }
  }
}
</style>