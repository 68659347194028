<template>
  <div class="chart-box">
    <!-- 标题 -->
    <div class="title">24小时走势</div>
    <!-- 图表 -->
    <div class="chart" :id="id"></div>
  </div>
</template>
    
<script setup>
// 导入
import { Column } from '@antv/g2plot'
import Pub from '@/utils/public'

const id = Pub.GetUniqueID()
function showColumn(data) {
  // 创建
  const chart = new Column(id, {
    // 数据源
    data: data.dataSource,
    // x轴的分组字段
    xField: 'hour',
    // 每个x分组对应的柱状字段的值
    yField: 'value',
    // 每个x分组对应的柱状字段的值的解释
    seriesField: 'type',
    // 是否分组柱状图
    isGroup: 'true',
    // 指定柱状图最大最小宽度
    minColumnWidth: 9,
    maxColumnWidth: 9,
    // y轴的 grid 网格线条样式
    yAxis: {
      grid: {
        line: {
          style: {
            // 线宽
            lineWidth: 0.5,
            // 虚线配置，第一个值为虚线每个分段的长度，第二个值为分段间隔的距离
            lineDash: [7, 8],
          }
        }
      }
    },
    // 柱状图样式
    columnStyle: {
      // 圆角
      radius: [0, 0, 0, 0],
    },
    // 提示
    tooltip: {
      // 标题，设置的值，如果为字段key，则会从数据源中取值显示，如果数据源没有该key，则直接显示
      // title: 'team',
      // 允许鼠标滑入提示框
      // enterable: true,
      // 自定义
      // containerTpl: `
      //   <div class="g2-tooltip">
      //     <div class="g2-tooltip-title"></div>
      //     <ul class="g2-tooltip-list"></ul>
      //     <li class="g2-tooltip-list-item">
      //       <span class="g2-tooltip-marker"></span>
      //       <span class="g2-tooltip-name">总计</span>:
      //       <span class="g2-tooltip-value">100000</span>
      //     </li>
      //   </div>
      // `,
      // // 自定义 item 模板
      // itemTpl: `
      //   <li class="g2-tooltip-list-item">
      //     <span class="g2-tooltip-marker" style="background-color: {color};"></span>
      //     <span class="g2-tooltip-name">{name}</span>:
      //     <span class="g2-tooltip-value">{value}</span>
      //   </li>
      // `,
      // 是否显示 hover 辅助线
      showCrosshairs: true,
      // 辅助线配置
      crosshairs: {
        // x 表示 x 轴上的辅助线，y 表示 y 轴上的辅助线，xy 表示两条辅助线
        type: 'x',
        // 辅助线是否跟随鼠标移动
        follow: false,
        // 辅助线配置
        line: {
          // 样式配置
          style: {
            // 线宽
            lineWidth: 0.5
          }
        }
      }
    },
    // 图例列表
    legend: {
      // 隐藏瞄准器
      radio: false,
      // 摆放位置
      position: 'bottom',
      // y轴偏移
      offsetY: 15,
      // 图例高度
      itemHeight: 28,
      // 配置图例
      marker: {
        // 图例样式
        style: (style) => {
          // 重组样式
          return {
            // 半径
            r: 3,
            // 样式类型
            symbol: 'circle',
            // 填充
            fill: style.fill || style.stroke,
            // 边框线宽
            lineWidth: 1,
            // 边框填充颜色
            stroke: style.fill || style.stroke,
            // 边框透明度
            strokeOpacity: 1,
            // 线圆角
            lineCap: 'round',
            lineJoin: 'round'
          }
        }
      }
    },
    // 主题配置
    theme: {
      // 分类个数小于 10 时使用
      colors10: ['#3AA0FF', '#36CBCB', '#FAD337']
      // 分类个数大于 10 时使用
      // colors20: ['#A685FF', '#FBD86D']
    },
    // 添加交互（'default' | 'active' | 'inactive' | 'selected'）
    interactions: [
      // 激活样式
      { type: 'element-active' }
    ],
    // 交互状态配置
    state: {
      // 设置 active 激活状态的样式
      active: {
        // 动画配置
        animate: {
          duration: 100,
          easing: 'easeLinear'
        },
        // 动画样式
        style: {
          lineWidth: 1,
          stroke: 'rgba(0, 0, 0, 0.25)'
        }
      }
    }
  })
  // 绘制
  chart.render()
}

// 暴露出去
defineExpose({
  showColumn
})
</script>
    
<style lang="less" scoped>
.chart-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .title {
    font-size: 16px;
  }
  .chart {
    flex: 1;
    padding-top: 60px;
  }
}
</style>