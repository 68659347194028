<template>
  <div class="chart-box">
    <!-- 标题 -->
    <div class="title">{{ info.title }}</div>
    <!-- 数据 -->
    <div class="num">{{ info.num }}</div>
    <!-- 图表 -->
    <div class="chart" :id="id"></div>
    <!-- 描述 -->
    <div class="desc">{{ info.desc }}<span class="text">{{ info.totalNum }}</span></div>
  </div>
</template>
  
<script setup>
// 导入
import { Area } from '@antv/g2plot'
import { ref } from 'vue'
import Pub from '@/utils/public'

const id = Pub.GetUniqueID()
let info = ref({
  title: '',
  num: '0',
  desc: '',
  totalNum: ''
})
function showTinyArea (data) {
  info.value = data
  const chart = new Area(id, {
    // 数据源
    data: data.dataSource,
    // x轴字段
    xField: 'date',
    // y轴字段
    yField: 'value',
    // padding值
    padding: [6, 0, 0, 0],
    // 高度
    height: 60,
    // 自适应容器宽高，设置为 true 时，width 和 height 的设置将失效。
    autoFit: true,
    // 线条是否为弧度
    smooth: true,
    // 配置线样式
    line: {
      // 颜色
      color: data.background
    },
    areaStyle: {
      fill: data.background,
      fillOpacity: 1,
      strokeOpacity: 0
    },
    // 坐标轴隐藏
    xAxis: false,
    yAxis: false,
    // tooltip
    tooltip: {
      // 格式化 tooltip item 内容
      formatter: (datum) => {
        return { name: data.tooltipDesc || '数量', value: datum.value }
      },
      // 手动修改marker的颜色
      domStyles: {
        'g2-tooltip-marker': {
          'background': data.background
        }
      }
    }
  })
  // 绘制
  chart.render()
}

// 暴露出去
defineExpose({
  showTinyArea
})
</script>
  
<style lang="less" scoped>
.chart-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  .title {
    font-size: 16px;
    color: #7f7f81;
  }
  .num {
    font-size: 36px;
  }
  .chart {
    margin: 20px 0;
  }
  .desc {
    border-top: 1px solid #eee;
    height: 44px;
    line-height: 44px;
    .text {
      margin-left: 10px;
    }
  }
}

</style>