<template>
  <div class="chart-box">
    <!-- 标题 -->
    <div class="title">{{ info.title }}</div>
    <!-- 数据 -->
    <div class="num">{{ info.num }}%</div>
    <!-- 虚假占位 -->
    <div class="chart"></div>
    <!-- 图表 -->
    <div class="ring" :id="id"></div>
    <!-- 描述 -->
    <div class="desc">{{ info.desc }}<span class="text">{{ info.totalNum }}</span></div>
  </div>
</template>
    
<script setup>
// 导入
import { RingProgress } from '@antv/g2plot'
import { ref } from 'vue'
import Pub from '@/utils/public'

const id = Pub.GetUniqueID()
let info = ref({
  title: '',
  num: '0',
  desc: '',
  totalNum: ''
})
function showRingProgress(data) {
  info.value = data
  const chart = new RingProgress(id, {
    // 高度
    height: 100,
    // 宽度
    width: 100,
    // 自适应容器宽高，当 autoFit 设置为 true 时，width 和 height 的设置将失效
    autoFit: true,
    // 进度
    percent: data.num / 100,
    // 主题色
    color: [data.background, '#E9E9E9'],
    // 内半径，也算是显示进度条宽度
    innerRadius: 0.65,
    //  画布宽高的最小值比例作为半径（0-1）
    radius: 0.98,
    // 静态内容
    statistic: {
      // 标题
      title: {
        // 样式
        style: {
          color: 'rgba(0, 0, 0, 0.45)',
          fontSize: '14px',
          lineHeight: '14px'
        },
        // 内容
        // formatter: () => '1-1千',
      },
      // 内容样式
      content: {
        // 样式
        style: {
          color: 'rgba(0, 0, 0, 0.85)',
          fontSize: '18px',
          lineHeight: '30px',
          fontWeight: '500'
        },
        // 内容
        formatter: (datum) => {
          // return `${datum.percent * 100}%`
        }
      }
    }
  })
  // 绘制
  chart.render()
}

// 暴露出去
defineExpose({
  showRingProgress
})
</script>
    
<style lang="less" scoped>
.chart-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  position: relative;

  .title {
    font-size: 16px;
    color: #9B9C9D;
  }

  .num {
    font-size: 36px;
  }

  .chart {
    height: 60px;
    margin: 20px 0;
  }

  .ring {
    position: absolute;
    right: 36px;
    top: 76px;
  }

  .desc {
    border-top: 1px solid #eee;
    height: 44px;
    line-height: 44px;

    .text {
      margin-left: 10px;
    }
  }
}
</style>